// pages/Hobbies.jsx

const Hobbies = () => {
  return (
    <div>
      This is the Hobbies page
    </div>
  );
};

export default Hobbies;
