import React from 'react';

const Contact = () => {
  return (
    <div>
      This is the contact page
    </div>
  );
};

export default Contact;
